.container {
  width: 100vw;
  height: 156px;
  background: #ffffff;
  display: flex;
  // position: relative;
  z-index: 100;

  justify-content: space-between;

  @media (max-width: 780px) {
    height: 68px;
    overflow-x: hidden;
    justify-content: flex-end;
  }

  .name {
    width: 180px;
    height: 137px;
    border: 2px solid rgba(12, 13, 13, 0.5);
    background: #fff;
    margin-left: 59px;
    margin-top: 59px;
    z-index: 10;

    @media (max-width: 760px) {
    margin-top: 10px;
    border: none;
    margin-left: 0px;
    width: 70px;
      height: 70px;
      // margin-left: 100px;

    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    display: flex;
    @media (max-width: 760px) {
      display: flex;
            margin-left: 90px;
    }

    .list {
      display: flex;
      align-items: center;
      margin-top: 93px;
      margin-right: 66px;


      @media (max-width: 760px) {
        margin-top: 29px;
        margin-right: 120px;
        // width: 0px;
      }

      li {
        list-style: none;
        text-decoration: none;
        display: inline-block;
      }

      .home {
        display: flex;
        align-items: center;
        height: 48px;
        width: 93px;
        color: black;
        border-radius: 8px;
        margin-left: 30px;
        align-items: center;
        justify-content: center;

        a{
          list-style: none;
          text-decoration: none;
        }

        @media (max-width: 780px) {
          color: var(--black-800, #251F1F);
            font-size: 8px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            height: 0px;
            padding: 10px;
            width: 30px;
            margin-left: 0;
        }
      }

      .contact {
        background: rgba(16, 33, 125, 1);
        height: 48px;
        width: 93px;
        display: flex;
        align-items: center;
        color: #f6f6f6;
        border-radius: 8px;
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        a{
          text-decoration: none;
          color:#ffffff;
        }

        @media (max-width: 760px) {
         padding: 10px;
         width: 57px;
        color: var(--white, #F6F6F6);
          font-size: 8px;
          font-style: normal;
          font-weight: 600;
          height: 0px;
          line-height: 24px;
          margin-left: 14.5px;
        }
      }
    }
    .logo {
      margin-right: 65px;
      z-index: 10;
      margin-top: 18px;

      @media (max-width: 760px) {
        // padding-top: 3px;
        position: absolute; 
        right: 0;
        margin-right: 0;
        margin-top: 0;
        padding-top: 3px;
        // padding-right: 18px;

      }

      img {
        @media (max-width: 760px) {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}
