.container {
  text-align: center;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;

  p {
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    @media (max-width: 780px) {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  .first {
    color: #10217d;
    animation-name: fadeInScale;
    animation-delay: 3s;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    opacity: 1;
  }

  @keyframes fadeInScale {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    50% {
      // opacity: 1;
      // transform:scale(2);
      transform: scale(1.7);
    }

    100% {
      // opacity: 0%;
      transform: scale(1);
    }
  }

  .second {
    color: #000;
    animation-name: fadeInOut;
    animation-delay: 3s;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    opacity: 1;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
