.home{
    position: relative;

.home_leaf{
    display: none;
    @media(max-width:780px){
    position: absolute;
        width: 200px;
        height: 500px;
        bottom: 42%;
        display: block;
    }



img{
  width: 70%;
  height: 100%;
}
}

.community_leaf {
    position: absolute;
        width: 300px;
        height: 650px;
        bottom: 10%;
        right: 0;

    @media(max-width:780px) {
        position: absolute;
        width: 200px;
        height: 200px;
        bottom: 15%;
        right:0;
    }



    img {
        width: 90%;
        height: 100%;
        position: absolute;
        right: 0;
    }
}
}