.container {
  max-width: 1200px;
  width: 90%;
  display: flex;
  align-items: center;
  padding: 130px 0px;
  gap: 70px;
  margin: 0 auto;
  color: var(--black-800, #251f1f);

  @media(max-width:780px){
    flex-direction: column-reverse;
    padding: 40px 0;
  }

  .info {
    .title {
      h2 {
        font-size: 56px;
        font-weight: 700;
        line-height: 72px;

        @media (max-width: 760px) {
          font-size: 20px;
          line-height: 24px;
        }

        span {
          color: #10217d;
        }
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 70px;
      gap: 20px;
      margin-bottom: 44px;

      @media(max-width :780px){
        margin-top: 20px;

      }
     

      .list_item {
        display: flex;
        align-items: center;
  

        // .list_icon{

        // @media(max-width:780px){
        //   display: inline-block;
        //   width: 13.369px;
        //     height: 13px;
        // }

        // img{

        // @media(max-width:780px) {
        //     width: 13.369px;
        //     height: 13px;
        //   }
        // }
        // }

        .list_text {
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          padding-left: 10px;

          @media(max-width:780px){
            font-size: 16px;
            padding-left: 5px;
          }
          
        }
      }
    }

    .waitlist {
      .text {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;

        @media (max-width: 760px) {
          font-size: 14px;
        }

        span {
          color: #10217d;
        }
      }

      .subtext {
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        color: #263238;

        @media (max-width: 760px) {
          font-size: 14px;
         
          line-height: 12px;
        }
      }

      .input{
        margin-top: 31px;
      

        @media(max-width:780px){
          margin-top: 16px;
        }
      }
    }
  }



  .img {
    overflow: hidden;

    @media(max-width:780px){
  
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
