.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;

  @media (max-width: 760px) {
    // margin-top: 30px;
  }

  .contact_bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
      max-width: 900px;
      width: 85%;
    }
  }

  .content {
    .title {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #251f1f;

      @media (max-width: 780px) {
        font-size: 14px;
      }
    }

    .subtext {
      color: #251f1f;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      line-height: 45px;

      @media (max-width: 780px) {
        font-size: 16px;
      }
    }

    .input {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      z-index: 100;
      position: relative;
    }

    .foot_note {
      margin-top: 60px;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 35px;
      color: #251f1f;
      @media (max-width: 780px) {
        font-size: 14px;
      }
    }
  }
}

.footer {
  // max-width: 1440px;
  width: 100vw;
  margin: 0 auto;
  padding: 40px 0;
  background: #363d30;

  .logo {
    margin-left: 71px;

    @media (max-width: 780px) {
      margin-left: 8px;
    }
  }

  .footer_nav {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    .nav_content {
      display: flex;
      width: 100%;
      // border: 1px solid yellow;
      // justify-content:space-between;
      gap:300px;

      @media (max-width: 780px) {
        width: 90%;
        gap: 8px;
      }

      .details {
        color: #ffffff;
        margin-top: 40px;
        display: flex;
        align-items: center;

        @media (max-width: 780px) {
        }

        .text {
          font-size: 16px;
          padding-left: 8px;

        
          @media (max-width: 780px) {
            font-size: 10px;
          }
        }

        .img {
          // display: inline-block;
          width: 24px;
          height: 24px;
          display: flex;

          img {
            height: 100%;
            width: 100%;
            align-self: center;

            @media (max-width: 780px) {
              height: 80%;
              width: 80%;
            }
          }
        }
      }
    }
  }

  .divider {
    border: 1px solid #ffffff;
    margin: 40px auto;
    width: 80%;

    @media (max-width: 780px) {
      width: 100%;
    }
  }

  .social {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 5px;

    .social_container {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      border: 1px solid green;
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 10px;

      @media (max-width: 780px) {
        width: 20px;
        height: 20px;
      }

      img {
        width: 100%;
        height: 100%;

        @media (max-width: 780px) {
          width: 100%;
          height: 80%;
        }
      }
    }
  }

  .year {
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
    font-size: 14px;

    @media (max-width: 780px) {
      font-size: 10px;
    }
  }
}
