.financing {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
  display: flex;
  gap: 70px;
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }

  .pc_img {
    // width: 50%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    @media (max-width: 780px) {
    }

    img {
      width: 100%;
    }
  }

  .text_section {
    width: 50%;

    @media (max-width: 780px) {
      width: 100%;
    }

    .title {
      color: #251f1f;
      font-size: 66px;
      font-weight: 700;
      line-height: 72px;
      margin-bottom: 56px;

      @media (max-width: 780px) {
        color: #251f1f;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 13px;
        display: block;
      }

      span {
        color: #10217d;
        font-size: 66px;
        font-weight: 700;
        line-height: 72px;

        @media (max-width: 780px) {
          color: #10217d;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          display: block;
        }
      }
    }

    .subtext {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;

      @media (max-width: 780px) {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}

section {
  position: relative;

  .leaf_img {
    position: absolute;
    bottom: 0;

    @media (max-width: 760px) {
      display: none;
    }

    img {
      width: 70%;
      height: 100%;
    }
  }
}
