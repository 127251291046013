  
  .button{
        height: 48px;
        padding: 14px 48px;
        justify-content: center;
        align-items: center;
        background: #10217D;
        color: #f6f6f6;
        border-radius: 4px;
        border: none;
        

       

        @media(max-width:780px){
          height: 30px;
          padding: 6px 11px;
          width: 60px;
        }

       
  }

    btn-link{
          color: #f6f6f6;
          text-decoration: none;
          display: inline-block;
         }

         btn-link:hover{
          cursor: pointer;
         }
