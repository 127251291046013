.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;

  @media (max-width: 760px) {
  }

  .content {
    display: flex;
    justify-content: center;
    gap: 70px;

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column-reverse;
    }

    .text {
      width: 50%;
      @media (max-width: 780px) {
        width: 100%;
      }

      .main_text {
        color: #251f1f;
        font-size: 66px;
        font-style: normal;
        font-weight: 700;
        line-height: 72px;
        margin-bottom: 70px;

        @media (max-width: 780px) {
          color: #251f1f;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          width: 60%;
          margin-bottom: 13px;
        }
        span {
          color: #10217d;
          font-style: normal;
          font-weight: 700;
          line-height: 72px;

          @media (max-width: 780px) {
            color: #10217d;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }

      .subtext {
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        animation-name: fade;
        animation-delay: 3s;
        animation-duration: 9s;
        animation-iteration-count: infinite;
        opacity: 1;

        @media (max-width: 780px) {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          width: 90%;
        }
      }
      @keyframes fade {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.5;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .community {
      width: 50%;
      @media (max-width: 780px) {
        width: 100%;
        display: flex;
      }

      img {
        width: 100%;

        @media (max-width: 780px) {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
}
