.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;

  .bg {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;

    @media (max-width: 780px) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 85%;
    }

    img {
      max-width: 900px;
      width: 85%;

      @media (max-width: 780px) {
        max-width: 900px;
        width: 100%;
      }
    }
  }

  .content {
    position: relative;
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 40px;
    }

    .info {
      display: flex;
      justify-content: space-between;
      gap: 70px;
      align-items: center;

      @media (max-width: 760px) {
        display: flex;
        flex-direction: column-reverse;
      }

      .text_section {
        .main {
          color: #251f1f;
          font-size: 66px;
          font-style: normal;
          font-weight: 700;
          line-height: 72px;
          margin-bottom: 56px;

          @media (max-width: 760px) {
            color: #251f1f;
            /* P base bold */
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 13px;
          }

          span {
            color: #10217d;
            font-size: 66px;
            font-style: normal;
            font-weight: 700;
            line-height: 72px;

            @media (max-width: 760px) {
              color: #10217d;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              display: block;
            }
          }
        }
        .subtext {
          // height: 441px;

          color: #251f1f;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
          animation-name: fade;
          animation-delay: 3s;
          animation-duration: 9s;
          animation-iteration-count: infinite;
          opacity: 1;

          @media (max-width: 760px) {
            color: #251f1f;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }

        @keyframes fade {
          0% {
            opacity: 1;
          }

          50% {
            opacity: 0.5;
          }

          100% {
            opacity: 1;
          }
        }
      }

      .img_section {
        width: 100%;

        @media (max-width: 780px) {
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
