* {
  margin: 0;
  padding: 0;
  /* border: 1px solid green; */
  box-sizing: border-box;
}
body {
  margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-carousel .slick-dots {
  display: none !important;
}

.ant-carousel{
  position: relative;
  /* z-index: 1; */
}