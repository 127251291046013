.container {
 
  position: relative;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;



 

    .leaf_top{
       display: none;
      @media (max-width: 780px) {
       display: block;
       position: absolute;
      //  width: 300px;
        left: 50%;
          transform: translateX(-50%);

       
      }
    }


        .leaf_btm {
          display: none;
          @media (max-width: 780px) {
            display: block;
            position: absolute;
            bottom: 20%;
                              left: 50%;
                              transform: translateX(-50%);
       
          }
        }

  

  // .bg_btm {
  //   display: none;

  //   @media (max-width: 780px) {
  //     position: absolute;
  //     // width: 400px;
  //     // height: 400px;
  //     bottom: 48px;
  //     display: block;
  //     position: absolute;
  //     width: 70%;
  //     height: 50%;
  //     display: block;
  //   }

  //   img {
  //     @media (max-width: 780px) {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }
  .content {
     position: relative;
   
    .title {
      text-align: center;
      margin-bottom: 30px;

  
      p {
        color: #251f1f;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;

        @media (max-width: 780px) {
          font-size: 30px;
          font-weight: 600;
        }
      }

      span {
        color: #10217d;
      }
    }

    .card_section {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      @media (max-width: 760px) {
        display: flex;
        flex-direction: column;
        align-items: center;
              gap: 100px;
        
      }
    }
  }
}
